/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable no-console */
import { Stripe, StripeCardElement, StripeError } from '@stripe/stripe-js';
import { useActor } from '@xstate/react';
import { useHistory } from 'react-router-dom';
import { useAsyncEffect } from 'use-async-effect';
import { assign, createMachine, interpret, send, State, StateValue } from 'xstate';

import { triggerProcessing, uploadDocumentToService } from '../api/upload';
import { getPaymentIntent, getSubmissionInfo } from '../api/payment';
import { getSession, SessionMetadata } from '../api/session';
import { PaymentInputValues } from '../components/modals/payment-modal/payment-form';
import { wait } from '../util/helpers';
import { logToApm } from '../util/log-error';
import { track } from '../tracking/ga';

const PROCESSING_DOCUMENT_REFETCH_DELAY = 2000;

type UploadReceiveData = {
  previewText: string;
  hubbleId: string;
  documentId: string;
};

export type TContext = {
  document: File | null;
  preview: string | null;
  documentId: string | null;
  sessionId: string | null;
  hasMoreText: boolean;
  downloadUrl: string | null;
  paymentIntentSecret?: string;
  stripePublishableKey?: string;
  downloadAvailableDays?: number;
  downloadExpired?: boolean;
  paymentCaptureError?: StripeError;
};

export type TEvent =
  | { type: 'UPLOAD_INITIATE'; document: File }
  | { type: 'UPLOAD_START'; sessionId: string }
  | { type: 'UPLOAD_RECEIVE_DATA'; data: UploadReceiveData }
  | { type: 'UPLOAD_CANCEL' }
  | { type: 'UPLOAD_CLEAR' }
  | { type: 'UPLOADED' }
  | { type: 'INITIATE_DOCUMENT_PROCESSING' }
  | { type: 'GET_PROCESSING_STATUS' }
  | { type: 'PROCESSING_DOCUMENT_DONE'; url: string }
  | { type: 'PROCESSING_DOCUMENT_FAILED' }
  | { type: 'PAYMENT_IDLE' }
  | {
      type: 'PAYMENT_INTENT_READY';
      data: { paymentIntentSecret: string; stripePublishableKey: string };
    }
  | { type: 'PAYMENT_CAPTURED' }
  | { type: 'INITIATE_PAYMENT' }
  | {
      type: 'CAPTURE_PAYMENT';
      getCardElement: () => StripeCardElement;
      stripe: Stripe;
      values: PaymentInputValues;
    };

export type TState =
  | { value: 'uninitialized'; context: TContext }
  | { value: 'idle'; context: TContext }
  | { value: 'preUpload'; context: TContext }
  | { value: 'uploading'; context: TContext }
  | { value: 'uploaded'; context: TContext };
// TODO: types for the nested (parallel) states

if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
  throw new Error('Stripe publishable key not found in environment variables');
}

const defaultContext = {
  document: null,
  preview: null,
  documentId: null,
  sessionId: null,
  downloadUrl: null,
  hasMoreText: false,
};

const documentMachine = createMachine<TContext, TEvent, TState>(
  {
    id: 'document',
    // strict: true, leave this off for now, we're sending a bogus event to workaround https://github.com/statelyai/xstate/issues/2434
    initial: 'uninitialized',
    context: defaultContext,
    on: {
      UPLOAD_CLEAR: { target: '.idle', actions: 'clearContext' },
      UPLOAD_INITIATE: { target: '.preUpload', actions: 'clearAndAssignDocumentToContext' },
    },
    states: {
      uninitialized: {
        on: {},
      },
      idle: {
        on: {},
      },
      preUpload: {
        on: {
          UPLOAD_START: { target: 'uploading' },
        },
      },
      uploading: {
        invoke: {
          src: 'uploadDocument',
          onDone: {
            target: 'uploaded',
            actions: 'assignPreviewToContext',
          },
          onError: 'idle',
        },
        on: {
          UPLOAD_CANCEL: { target: 'idle' },
        },
      },
      uploaded: {
        type: 'parallel',
        states: {
          documentProcessing: {
            initial: 'idle',
            states: {
              idle: {
                on: {
                  INITIATE_DOCUMENT_PROCESSING: 'initiatingProcessing',
                  GET_PROCESSING_STATUS: 'gettingProcessingStatus',
                },
              },
              initiatingProcessing: {
                tags: 'documentIsProcessing',
                invoke: {
                  src: 'initiateProcessing',
                  onDone: 'idle',
                  onError: 'failed',
                },
              },
              gettingProcessingStatus: {
                tags: 'documentIsProcessing',
                invoke: {
                  src: 'getProcessingStatus',
                },
                on: {
                  INITIATE_DOCUMENT_PROCESSING: 'initiatingProcessing',
                  // re-enters self state to trigger getting processing status
                  GET_PROCESSING_STATUS: 'gettingProcessingStatus',
                  PROCESSING_DOCUMENT_DONE: {
                    target: 'done',
                    actions: 'assignDownloadUrlToContext',
                  },
                  PROCESSING_DOCUMENT_FAILED: 'failed',
                },
              },
              done: {
                tags: 'documentDone',
                type: 'final',
              },
              failed: {
                tags: 'documentFailed',
                type: 'final',
              },
            },
          },
          payment: {
            initial: 'alwaysGoToUninitialized',
            states: {
              // workaround for bug in xstate where restoring to a state won't invoke it's service, so we restore to this intermediate state https://github.com/statelyai/xstate/issues/2434
              alwaysGoToUninitialized: {
                always: 'uninitialized',
              },
              uninitialized: {
                invoke: {
                  src: 'getSubmissionStatus',
                },
                on: {
                  PAYMENT_IDLE: 'idle',
                  PAYMENT_INTENT_READY: {
                    target: 'paymentIntentReady',
                    actions: 'assignPaymentIntent',
                  },
                  PAYMENT_CAPTURED: {
                    target: 'captured',
                    //todo: assign somethign to context, we are done here
                  },
                },
              },
              idle: {
                on: {
                  INITIATE_PAYMENT: 'gettingPaymentIntent',
                },
              },
              gettingPaymentIntent: {
                invoke: {
                  src: 'getPaymentIntent',
                  onDone: {
                    target: 'paymentIntentReady',
                    actions: 'assignPaymentIntent',
                  },
                  onError: {
                    // TODO: this happens if the intent has already been prepared once, we need to get the existing intent
                  },
                },
              },
              paymentIntentReady: {
                on: {
                  CAPTURE_PAYMENT: 'capturing',
                },
              },
              capturing: {
                tags: 'capturingPayment',
                invoke: {
                  src: 'capturePayment',
                  onDone: {
                    target: 'waitingForCapture',
                    actions: ['assignPaymentIntentStatus', 'removeCaptureError'],
                  },
                  onError: {
                    target: 'paymentIntentReady',
                    actions: 'assignCaptureError',
                  },
                },
              },
              waitingForCapture: {
                tags: 'capturingPayment',
                after: {
                  4000: { target: 'captured' },
                },
              },
              captured: {
                tags: 'paymentCaptured',
                type: 'final',
                entry: send('GET_PROCESSING_STATUS'),
                invoke: {
                  src: 'getSubmissionStatus',
                  onDone: {
                    actions: 'assignSubmissionStatus',
                  },
                },
              },
            },
          },
        },
        onDone: { actions: 'autoDownload' },
      },
    },
  },
  {
    actions: {
      autoDownload: (context) => {
        if (context.downloadUrl) {
          const link = document.createElement('a');
          link.href = context.downloadUrl;
          link.setAttribute('download', 'download');
          document.body.append(link);
          link.click();
          link.remove();
        }
      },
      clearContext: assign((context, event: any) => {
        return defaultContext;
      }),
      clearAndAssignDocumentToContext: assign((_, event: any) => {
        return {
          ...defaultContext,
          document: event.document,
        };
      }),
      assignPreviewToContext: assign((context, event: any) => {
        track('event', 'document', 'DOCUMENT_UPLOADED');
        return {
          ...context,
          preview: event.data.previewText,
          documentId: event.data.documentId,
          sessionId: event.data.sessionId,
          hasMoreText: event.data.hasMoreText,
        };
      }),
      assignDownloadUrlToContext: assign((context, event) => {
        if (event.type !== 'PROCESSING_DOCUMENT_DONE') {
          return context;
        }
        return {
          ...context,
          downloadUrl: event.url,
        };
      }),
      sendProcessingDocumentEvent: send('PROCESSING_DOCUMENT', {
        delay: PROCESSING_DOCUMENT_REFETCH_DELAY,
      }),
      assignPaymentIntent: assign({
        paymentIntentSecret: (_, event: any) => event.data.paymentIntentSecret,
        stripePublishableKey: (_, event: any) => event.data.stripePublishableKey,
      }) as any,
      assignSubmissionStatus: assign({
        downloadExpired: (_, event: any) => event.data.downloadExpired,
        downloadAvailableDays: (_, event: any) => event.data.downloadAvailableDays,
      }) as any,
      assignCaptureError: assign({
        paymentCaptureError: (_, event: any) => event.data,
      }) as any,
      removeCaptureError: assign({
        paymentCaptureError: undefined,
      }) as any,
    },
    services: {
      uploadDocument: async (context, event) => {
        try {
          if (event.type !== 'UPLOAD_START') {
            return logToApm('event type is not UPLOAD_START');
          }
          if (context.document === null) {
            return logToApm('context document does not exist');
          }
          return await uploadDocumentToService({
            document: context.document,
            sessionId: event.sessionId,
          });
        } catch (error) {
          logToApm(error);
          return error;
        }
      },
      initiateProcessing: (context) => async () => {
        return await triggerProcessing({
          documentId: context.documentId as string,
          sessionId: context.sessionId as string,
        });
      },
      getProcessingStatus:
        ({ sessionId, documentId }) =>
        async (callback) => {
          try {
            if (!sessionId || !documentId) {
              throw new Error(`missing sessionId[${sessionId}] or documentId[${sessionId}]`);
            }
            const res = await getSession(sessionId);

            switch (res.data.documentInfo.status) {
              case 'UPLOADED': {
                // should never happen but we'll just try to re-initiate the processing
                return callback('INITIATE_DOCUMENT_PROCESSING');
              }
              case 'SUBMITTED': {
                await wait(PROCESSING_DOCUMENT_REFETCH_DELAY);
                return callback('GET_PROCESSING_STATUS');
              }
              case 'SUCCESS': {
                return callback({
                  type: 'PROCESSING_DOCUMENT_DONE',
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  url: res.data.documentInfo.url!,
                });
              }
              case 'FAILED':
              default: {
                return callback('PROCESSING_DOCUMENT_FAILED');
              }
            }
          } catch {
            //FIXME: log error to APM;
            return callback('PROCESSING_DOCUMENT_FAILED');
          }
        },
      getSubmissionStatus:
        ({ documentId }) =>
        async (callback) => {
          if (!documentId) {
            throw new Error('Tried to get submission status without a document ID');
          }
          try {
            const response = await getSubmissionInfo(documentId);
            switch (response.status) {
              case 'PAYMENT_INITIATED': {
                callback({
                  type: 'PAYMENT_INTENT_READY',
                  data: {
                    paymentIntentSecret: response.intent.paymentIntentSecret,
                    stripePublishableKey: response.intent.stripePublishableKey,
                  },
                });
                break;
              }
              // eslint-disable-next-line no-fallthrough
              case 'PAYMENT_COMPLETED':
              case 'PAYMENT_REFUNDED':
              case 'EMAIL_SENT': {
                callback('PAYMENT_CAPTURED');
              }
            }
            return response;
          } catch (error) {
            if (error?.json?.errorCode === 'DOCUMENT_NOT_FOUND') {
              callback('PAYMENT_IDLE');
            }
          }
        },
      getPaymentIntent: async ({ sessionId, documentId }) => {
        if (!sessionId || !documentId) {
          throw new Error('Tried to get payment intent without a session ID or document ID');
        }
        const response = await getPaymentIntent({
          sessionId,
          documentId,
          search: document.location.search,
        });

        return {
          paymentIntentSecret: response.intent.paymentIntentSecret,
          stripePublishableKey: response.intent.stripePublishableKey,
        };
      },
      capturePayment: async (context, event) => {
        const { values, getCardElement, stripe } = event as unknown as {
          type: 'CAPTURE_PAYMENT';
          getCardElement: () => StripeCardElement;
          stripe: Stripe;
          values: PaymentInputValues;
        };

        if (!context.paymentIntentSecret) {
          throw new Error(
            'Tried to capture payment without a payment intent secret in the context.',
          );
        }

        /* eslint-disable camelcase */
        // TODO: handle errors
        const response = await stripe.confirmCardPayment(context.paymentIntentSecret, {
          payment_method: {
            card: getCardElement(),
            billing_details: {
              name: values.name,
              email: values.email,
              address: {
                postal_code: values.zipCode,
                city: values.city,
                country: values.country,
              },
            },
          },
        });
        /* eslint-enable camelcase */
        if (response.error) {
          throw response.error;
        }
        track('event', 'document', 'DOCUMENT_PURCHASED');
        return response;
      },
    },
    guards: {},
  },
);

const documentService = interpret(documentMachine, {
  devTools: true,
});

export const useDocumentState = () => {
  const { replace } = useHistory();
  // TODO: probably move this effect somewhere else, maybe to the document context
  useAsyncEffect(async () => {
    const sessionId = window.location.pathname.split('/')[1];

    if (!sessionId) {
      // not restoring any session
      documentService.start('idle');
      return;
    }
    let sessionMetadata: SessionMetadata['data'];
    try {
      const response = await getSession(sessionId);
      sessionMetadata = response.data;
    } catch (error) {
      // could not get session metadata, so we're navigating as if we didn't have a session ID
      replace('/');
      logToApm(error);
      documentService.start('idle');
      return;
    }
    const restoredContext: TContext = {
      document: null,
      preview: sessionMetadata.documentInfo.previewText,
      documentId: sessionMetadata.documentInfo.documentId,
      sessionId,
      downloadUrl: sessionMetadata.documentInfo.url || null,
      hasMoreText: sessionMetadata.documentInfo.hasMoreText,
    };

    let restoredStateValue: StateValue;
    // TODO: convert this to an object Map when we need to handle all the statuses
    switch (sessionMetadata.documentInfo.status) {
      case 'UPLOADED': {
        restoredStateValue = 'uploaded';
        break;
      }
      case 'SUBMITTED': {
        restoredStateValue = {
          uploaded: {
            documentProcessing: 'gettingProcessingStatus',
          },
        };
        break;
      }
      case 'SUCCESS': {
        restoredStateValue = {
          uploaded: {
            documentProcessing: 'done',
          },
        };
        break;
      }
      default: {
        throw new Error(
          `unknown state to restore from sessionMetadata: ${sessionMetadata.documentInfo.status}`,
        );
      }
    }
    const resolvedState = documentMachine.resolveState(
      State.from<TContext, TEvent>(restoredStateValue, restoredContext),
    );
    documentService.start(resolvedState);
    documentService.send('NON_EXISTING_EVENT' as any);
  }, []);

  return useActor(documentService);
};
